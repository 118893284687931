import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from "@angular/material/autocomplete";
import {MatFormField, MatHint} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {DmsSzervUserItemWithNames} from "../../../../../model/dmsSzervUserItemWithNames";
import {map, Observable, startWith} from "rxjs";
import {DmsUsersService} from "../../../../../services/api/dms-users.service";
import {MatTooltip} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-meta-data-szervuser-ac',
  standalone: true,
  imports: [
    AsyncPipe,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatFormField,
    MatHint,
    MatInput,
    MatOption,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    MatTooltip,
    NgClass,
    TranslateModule
  ],
  templateUrl: './meta-data-szervuser-ac.component.html',
  styleUrls: ['../metadata-style.scss']
})
export class MetaDataSzervuserAcComponent extends BaseMetadataAbstract<string|null, string, string> implements OnInit{

  displayValue: string = '';

  found: DmsSzervUserItemWithNames[] = [];

  myControl = new FormControl<string | DmsSzervUserItemWithNames>('');
  filteredOptions!: Observable<DmsSzervUserItemWithNames[]>;


  constructor(
    private dmsUserService: DmsUsersService,
  ) {
    super();
  }

  ngOnInit(){
    if(this.humanvalue) {
      this.displayValue = this.humanvalue;
    }
  }

  displayFn(option: any): string {
    return option && option.userName && option.orgName ? option.userName + ' - ' + option.orgName : '';
  }

  private _filter(name: string) {
    const filterValue = name.toLowerCase();
    return this.found.filter(option => option.userName.toLowerCase().includes(filterValue));
  }

  timeoutId!: any;
  counter = 0;

  showAll(value: string){
    if(value.length>2&&!value.includes('---')) {
      this.found = [];
      this.counter++;
      this.timeoutId = setTimeout(() => {
        this.counter--;

        if (this.counter === 0) {
          this.dmsUserService.readDmsAcUser$(value).subscribe( response => {
            this.found = response.result;
            if(this.visibility != 'MT_K') {
              this.found.unshift({userId: 0, userName: '---', orgId: 0, orgName: ''});
            }
            this.filtering();

          });
        }
      }, 150);
    } else {
      if(this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
      this.counter = 0;
    }
  }

  filtering(){
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.userName;
        return name ? this._filter(name as string) : this.found.slice();
      }),
    );
  }

}
