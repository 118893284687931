import {Component, OnDestroy, OnInit} from '@angular/core';
import { navbarDataUser, navbarDataAdmin} from "./nav-data";
import {SidenavToggleService} from "../../../services/shared/sidenav-toggle.service";
import {ActivationStart, Router} from "@angular/router";
import {UserEmailService} from "../../../services/component/sidenav/user-email.service";
import {filter, Subscription} from "rxjs";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy{

  isAdminPage: boolean = false;

  selectedLang = 'hu';

  private langChangeSub!: Subscription;

  languages = [
    { code: 'hu', name: 'Magyar' },
    { code: 'en', name: 'English' },
    { code: 'de', name: 'Deutsch' },
    { code: 'fr', name: 'Français' }
  ];

  constructor(
    public sidenavToggle: SidenavToggleService,
    public router: Router,
    public userEmailService: UserEmailService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.selectedLang = localStorage.getItem('lang') || 'hu';
    this.translateService.use(this.selectedLang);
    this.langChangeSub = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLang = event.lang;
    });
    this.router.events.pipe(
      filter(event => Reflect.has(event, 'snapshot') && event instanceof ActivationStart)
    ).subscribe((event: any)=>{
      this.isAdminPage = Boolean(event.snapshot.data.admin);
    })
  }

  ngOnDestroy() {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }

  logOut(){
    this.userEmailService.emailAddress=''; //todo: ez csak ideiglenes, ki kell jelentkeztetni a felhasználót
    this.userEmailService.setLoggedInEmail$('');
    this.router.navigate(['/login']);
  }

  isAdmin(){
    if(this.isAdminPage){
      return navbarDataAdmin;
    } else {
      return navbarDataUser;
    }
  }

  changeLanguage(lang: string) {
    this.selectedLang = lang;

    localStorage.setItem('lang', this.selectedLang);

    this.translateService.use(this.selectedLang)
  }

  getLanguage(){
    const language = this.languages.find(language =>
      language.code === this.selectedLang);
    return language ? language.name : this.selectedLang;
  }

}
