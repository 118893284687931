<mat-action-list>

  <h1 class="general-margin" *ngIf="!menuItem">{{listTitle}}</h1>

  <ng-container *ngFor="let item of items">
    <mat-list-item>
      <div matListItemAvatar
           *ngIf="item.status"
           class="mat-avatar"
           (click)="setRouterLink(item)">
        <mat-icon>{{changeIcon(item.status)}}</mat-icon>
      </div>
      <div matListItemTitle
           (click)="setRouterLink(item)">
        {{item.title}}
      </div>

      <div matListItemLine
           *ngIf="item.template&& item.templateVersion && item.id"
           (click)="setRouterLink(item)">
        {{item.template}} (Verzió: {{item.templateVersion}})
      </div>

      <div matListItemLine
           *ngIf="item.subject && item.id"
           (click)="setRouterLink(item)">
        {{item.subject}}
      </div>
      <div matListItemLine
           *ngIf="item.subject && item.stationId"
           (click)="setRouterLink(item)">
        Code: {{item.subject}}
      </div>

      <div matListItemLine
           *ngIf="item.deadline"
           (click)="setRouterLink(item)">
        Határidő: {{item.deadline}}
      </div>
      <div matListItemLine
           *ngIf="item.created"
           (click)="setRouterLink(item)">
        Létrehozva: {{item.created}}
      </div>
      <div matListItemMeta
           *ngIf="item.status&&menuItem&&item.id"
           class="mat-list-item">
        <button mat-icon-button
                (click)="selectItem(item.id)"
                [matMenuTriggerFor]="checkDone(item.status, awaiting, task, finalized, document)">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
      <div matListItemMeta
           *ngIf="item.attachmentGuid"
           class="mat-list-item">
        <button mat-icon-button [matTooltip]="'Aláírás'" matTooltipPosition="below"
                (click)="onSignAttachmentClicked(item.attachmentGuid)">
          <mat-icon>gesture</mat-icon>
          <!--<mat-icon>border_color</mat-icon>-->
        </button>
        <button mat-icon-button [matTooltip]="'Letöltés'" matTooltipPosition="below"
                (click)="onDownloadAttachmentClicked(item.attachmentGuid)">
          <mat-icon>save_alt</mat-icon>
        </button>
      </div>
    </mat-list-item>
    <mat-divider class="divider-position"></mat-divider>
  </ng-container>
</mat-action-list>

<mat-menu #awaiting="matMenu" xPosition="before">
  <button mat-menu-item routerLink="/{{routerType}}/{{selectedId}}">Adatok megtekintése</button>
  <button mat-menu-item (click)="onAcceptClicked()">Feladat elfogadása</button>
  <button mat-menu-item *ngIf="routerType=='task'" (click)="onDenyClicked()">Feladat elutasítása</button>
  <button mat-menu-item routerLink="/{{routerType}}/attachment-list/{{selectedId}}">Csatolmányok</button>
  <button mat-menu-item routerLink="/{{routerType}}/comment-list/{{selectedId}}">Megjegyzések</button>
  <button mat-menu-item (click)="onSendMessage()">Megjegyzés írása</button>
</mat-menu>
<mat-menu #task="matMenu" xPosition="before">
  <button mat-menu-item routerLink="/{{routerType}}/{{selectedId}}">Adatok megtekintése</button>
  <button mat-menu-item routerLink="/{{routerType}}/do/{{selectedId}}">Feladat elvégzése</button>
  <button mat-menu-item *ngIf="routerType=='task'" (click)="onDenyClicked()">Feladat elutasítása</button>
  <button mat-menu-item (click)="onForwardTask()">Átirányítás</button>
  <button mat-menu-item routerLink="/{{routerType}}/attachment-list/{{selectedId}}">Csatolmányok</button>
  <button mat-menu-item routerLink="/{{routerType}}/comment-list/{{selectedId}}">Megjegyzések</button>
  <button mat-menu-item (click)="onSendMessage()">Megjegyzés írása</button>
</mat-menu>
<mat-menu #finalized="matMenu" xPosition="before">
  <button mat-menu-item routerLink="/{{routerType}}/{{selectedId}}">Adatok megtekintése</button>
  <button mat-menu-item routerLink="/{{routerType}}/attachment-list/{{selectedId}}">Csatolmányok</button>
  <button mat-menu-item routerLink="/{{routerType}}/comment-list/{{selectedId}}">Megjegyzések</button>
  <!--<button mat-menu-item routerLink="/{{routerType}}/write-comment/{{selectedId}}">Megjegyzés írása</button>-->
</mat-menu>
<mat-menu #document="matMenu" xPosition="before">
  <button mat-menu-item routerLink="/document/{{selectedId}}">Adatok megtekintése</button>
  <button mat-menu-item routerLink="/document/related-documents/{{selectedId}}">Kapcsolódó dokumentumok</button>
  <button mat-menu-item routerLink="/document/attachment-list/{{selectedId}}">Csatolmányok</button>
  <button mat-menu-item routerLink="/document/comment-list/{{selectedId}}">Megjegyzések</button>
  <button mat-menu-item (click)="onSendMessage()">Megjegyzés írása</button>
</mat-menu>
