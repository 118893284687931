import {Component, OnInit} from '@angular/core';
import {MatError, MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {SharedModule} from "../../../../../modules/shared/shared.module";
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {FormControl, ValidatorFn, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-meta-data-workflow',
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    NgIf,
    SharedModule,
    TranslateModule
  ],
  templateUrl: './meta-data-workflow.component.html',
  styleUrls: ['../metadata-style.scss']
})
export class MetaDataWorkflowComponent extends BaseMetadataAbstract<string, string, string> implements OnInit{

  workflowFormControl!: FormControl;

  ngOnInit() {
    this.initializeFormControl();
    this.workflowFormControl.markAsTouched();
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [
    ];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }

    this.workflowFormControl = new FormControl(this.value, validators);
  }

}
