import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./components/mvp/dashboard/dashboard.component";
import {LoginComponent} from "./components/login/login.component";
import {AdminDashboardComponent} from "./components/admin/admin-dashboard/admin-dashboard.component";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {DevTestPageComponent} from "../mvp-auth/components/dev-test-page/dev-test-page.component";
import {AuthPageComponent} from "../mvp-auth";
import {NewsResolver} from "./resolvers/news.resolver";
import {DocusignFinishedComponent} from "./components/mvp/shared/docusign-finished/docusign-finished.component";
import {PageloadingComponent} from "./components/mvp/dashboard/pageloading/pageloading.component";
import {adminGuard} from "./guards/admin.guard";

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, data: { noHeader: true } },
  {path: 'dev/login/demo', component: DevTestPageComponent, data: { noHeader: true } },
  {path: 'dev/login', component: AuthPageComponent, data: { noHeader: true } },

  {path: 'dashboard', component: DashboardComponent, resolve: {requestData: NewsResolver}},
  {path: 'pageloading', component: PageloadingComponent, data: { noHeader: true} },

  {path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [adminGuard], data: { admin: true} },

  { path: 'workflow', loadChildren: () => import('./modules/workflow/workflow.module').then(m => m.WorkflowModule) },
  { path: 'task', loadChildren: () => import('./modules/task/task.module').then(m => m.TaskModule) },
  { path: 'document', loadChildren: () => import('./modules/document/document.module').then(m => m.DocumentModule) },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },

  { path: 'docusign/finished', component: DocusignFinishedComponent, data: { noHeader: true } },

  { path: '404', component: NotFoundComponent, data: { noHeader: true } },
  { path: '**', redirectTo:'404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
