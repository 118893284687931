import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ListViewComponent} from "../../components/shared/list-view/list-view.component";
import {MatMenuModule} from "@angular/material/menu";
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MessageFieldComponent} from "../../components/shared/message-field/message-field.component";
import {WriteCommentComponent} from "../../components/mvp/shared/write-comment/write-comment.component";
import {CommentListComponent} from "../../components/mvp/shared/comment-list/comment-list.component";
import {AttachmentListComponent} from "../../components/mvp/shared/attachment-list/attachment-list.component";
import {ForwardComponent} from "../../components/mvp/shared/forward/forward.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {InfoCardComponent} from "../../components/shared/info-card/info-card.component";
import {ChipsetComponent} from "../../components/shared/chipset/chipset.component";
import {SearchbarComponent} from "../../components/shared/searchbar/searchbar.component";
import {MatChipsModule} from "@angular/material/chips";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTableModule} from "@angular/material/table";
import {MatOptionModule} from "@angular/material/core";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
//import {FeedCardComponent} from "../../components/shared/feed-card/feed-card.component";
import {MatBadgeModule} from "@angular/material/badge";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatExpansionModule} from "@angular/material/expansion";
import {MetaDataDateComponent} from "../../components/mvp/shared/metadata/meta-data-date/meta-data-date.component";
import {
  MetaDataFoszamComponent
} from "../../components/mvp/shared/metadata/meta-data-foszam/meta-data-foszam.component";
import {
  MetaDataMftorzsComponent
} from "../../components/mvp/shared/metadata/meta-data-mftorzs/meta-data-mftorzs.component";
import {
  MetaDataMftorzsAcComponent
} from "../../components/mvp/shared/metadata/meta-data-mftorzs-ac/meta-data-mftorzs-ac.component";
import {MetaDataMoneyComponent} from "../../components/mvp/shared/metadata/meta-data-money/meta-data-money.component";
import {
  MetaDataNumberComponent
} from "../../components/mvp/shared/metadata/meta-data-number/meta-data-number.component";
import {
  MetaDataOptionComponent
} from "../../components/mvp/shared/metadata/meta-data-option/meta-data-option.component";
import {
  MetaDataPartnerComponent
} from "../../components/mvp/shared/metadata/meta-data-partner/meta-data-partner.component";
import {MetaDataTextComponent} from "../../components/mvp/shared/metadata/meta-data-text/meta-data-text.component";
import {
  MetaDataTextareaComponent
} from "../../components/mvp/shared/metadata/meta-data-textarea/meta-data-textarea.component";
import {MetaDataCheckComponent} from "../../components/mvp/shared/metadata/meta-data-check/meta-data-check.component";
import {
  MetaDataAlszamComponent
} from "../../components/mvp/shared/metadata/meta-data-alszam/meta-data-alszam.component";
import {
  MetaDataPartnerListComponent
} from "../../components/mvp/shared/metadata/meta-data-partner-list/meta-data-partner-list.component";
import { DialogMessageBoxComponent} from "../../components/shared/dialog-message-box/dialog-message-box.component";
import { DialogForwardBoxComponent} from "../../components/shared/dialog-forward-box/dialog-forward-box.component";
import {MatDialogModule} from "@angular/material/dialog";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    WriteCommentComponent,
    CommentListComponent,
    AttachmentListComponent,
    ForwardComponent,
    ListViewComponent,
    MessageFieldComponent,
    InfoCardComponent,
    //FeedCardComponent,
    ChipsetComponent,
    SearchbarComponent,
    MetaDataDateComponent,
    MetaDataFoszamComponent,
    MetaDataMftorzsComponent,
    MetaDataMftorzsAcComponent,
    MetaDataMoneyComponent,
    MetaDataNumberComponent,
    MetaDataOptionComponent,
    MetaDataPartnerComponent,
    MetaDataTextComponent,
    MetaDataTextareaComponent,
    MetaDataCheckComponent,
    MetaDataAlszamComponent,
    MetaDataPartnerListComponent,
    DialogMessageBoxComponent,
    DialogForwardBoxComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        RouterModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatCardModule,
        MatSelectModule,
        MatChipsModule,
        MatTableModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatBadgeModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatDialogModule,
        TranslateModule
    ],
  exports: [
    ListViewComponent,
    MessageFieldComponent,
    WriteCommentComponent,
    CommentListComponent,
    AttachmentListComponent,
    ForwardComponent,
    InfoCardComponent,
    //FeedCardComponent,
    ChipsetComponent,
    SearchbarComponent,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatFormFieldModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatListModule,
    MatDatepickerModule,
    RouterModule,
    MatTooltipModule,
    MatMenuModule,
    MatSelectModule,
    MatChipsModule,
    CommonModule,
    MatCheckboxModule,
    MetaDataDateComponent,
    MetaDataFoszamComponent,
    MetaDataMftorzsComponent,
    MetaDataMftorzsAcComponent,
    MetaDataMoneyComponent,
    MetaDataNumberComponent,
    MetaDataOptionComponent,
    MetaDataPartnerComponent,
    MetaDataTextComponent,
    MetaDataTextareaComponent,
    MetaDataCheckComponent,
    MetaDataAlszamComponent,
    MetaDataPartnerListComponent,
    MatDialogModule,
    DialogMessageBoxComponent,
    DialogForwardBoxComponent
  ]
})
export class SharedModule { }
