<div class="metadata-div">

  <span class="input-field-label" [matTooltip]="label" matTooltipPosition="above">
    <b>{{label}}</b>
  </span>

  <div *ngIf="visibility === 'MT_O'" class="only-read-metadata-data-div">
    {{humanvalue}}
  </div>

  <mat-form-field *ngIf="visibility === 'MT_K' || visibility === 'MT_M'">
    <mat-select [formControl]="szotarFormControl"
                [required]="visibility==='MT_K'"
                (selectionChange)=" setValue($event.value)"
                placeholder="{{humanvalue ? humanvalue : ('NOT_SELECTED' | translate)}}">
      <mat-option *ngFor="let option of optionList" [value]="option">
        {{option.dictItemName}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="szotarFormControl.hasError('required')">
      {{'MANDATORY_FIELD' | translate}}
    </mat-error>
  </mat-form-field>

</div>
