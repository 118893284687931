<div class="metadata-div">

  <span class="input-field-label" [matTooltip]="label" matTooltipPosition="above">
    <b>{{label}}</b>
  </span>

  <div *ngIf="visibility === 'MT_O'" class="only-read-metadata-data-div">
    {{humanvalue}}
  </div>

  <mat-form-field  *ngIf="visibility === 'MT_K' || visibility === 'MT_M'"
                   [ngClass]="{'error-state': visibility==='MT_K' && !value}">
    <input matInput
           [placeholder]="displayValue"
           [formControl]="szotarAcFormControl"
           [matAutocomplete]="auto"
    >
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                  (click)="onChangeValue(option.dictItemId); send(option); onChangeHumanValue(option.dictItemName)">
        {{option.dictItemName}}
      </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="visibility==='MT_K' && !value" class="error-message">
      {{'MANDATORY_FIELD' | translate}}
    </mat-hint>
  </mat-form-field>
</div>
