/**
 * User: harold
 * Date: 2023.08.07.
 */
import {createReducer, on} from "@ngrx/store";
import * as action from "./login-state.action";
import {AuthStateEnum, AuthTypeEnum, LoginState} from "../types";

const initialState: LoginState = {
  authType: AuthTypeEnum.NotLoggedIn,
  authState: AuthStateEnum.Unknown,
  credentials: undefined,
  stationList: [],
  provider: undefined,
  providerToken: undefined,
}

export const loginStateReducer = createReducer<LoginState>(
  initialState,
  //on(loggedIn, (state) => ({...state})),
  //on(loggedIn),
  // on(action.loggedIn, (state, {credentials}) => {
  //   console.log('on-loggedIn', state, credentials);
  //   return {
  //     ...state,
  //     credentials: credentials,
  //   };
  // }),
  on(action.logout, (state, {provider}) => ({
    ...state,
    authType: AuthTypeEnum.NotLoggedIn,
    authState: AuthStateEnum.Unknown,
    provider: undefined,
    providerToken: undefined,
    stationList: [],
    credentials: undefined,
  })),
  on(action.clear, (state) => ({
    ...state,
    authType: AuthTypeEnum.NotLoggedIn,
    authState: AuthStateEnum.Unknown,
    provider: undefined,
    providerToken: undefined,
    stationList: [],
    credentials: undefined,
  })),
  on(action.loginNeeded, (state) => ({
    ...state,
    authType: AuthTypeEnum.NotLoggedIn,
    authState: AuthStateEnum.LoginNeeded,
    stationList: [],
    credentials: undefined,
  })),
  on(action.loginCompleted, (state, {credentials}) => ({
    ...state,
    authType: credentials.station ? AuthTypeEnum.UserAndStation : AuthTypeEnum.UserOnly,
    authState: AuthStateEnum.LoginCompleted,
    credentials: credentials, // todo: ezzel mi legyen?
  })),
  on(action.loginAborted, (state) => ({
    ...state,
    authType: AuthTypeEnum.NotLoggedIn,
    authState: AuthStateEnum.LoginAborted,
    credentials: undefined, // todo: ezzel mi legyen?
  })),
  // on(action.stationNeeded, (state) => ({
  //   ...state,
  //   authzState: AuthzState.StationNeeded,
  // })),
  // on(action.stationCompleted, (state, {credentials}) => ({
  //   ...state,
  //   authzState: AuthzState.StationCompleted,
  //   credentials: credentials, // todo: ezzel mi legyen? ez itt meg mindig jo?
  // })),
  // on(action.stationAborted, (state) => ({
  //   ...state,
  //   authzState: AuthzState.StationAborted,
  // })),


  on(action.xloginSuccess, (state, {response}) => ({
    ...state,
    authType: response.activeStation ? AuthTypeEnum.UserAndStation : AuthTypeEnum.UserOnly,
    authState: AuthStateEnum.LoginCompleted,
    credentials: {
      email: response.email,
      provider: response.provider,
      station: response.activeStation,
      roles: response.roles
    },
    stationList: response.stationList,
  })),
  on(action.xloginMultiStation, (state, params) => ({
    ...state,
    authType: AuthTypeEnum.NotLoggedIn,
    authState: AuthStateEnum.LoginMultiStation,
    credentials: undefined,
    stationList: params.response.stationList,
    provider: params.provider,
    providerToken: params.providerToken,
  })),
  on(action.autoLogin, (state,) => ({
    ...state,
    authType: AuthTypeEnum.NotLoggedIn,
    credentials: undefined,
    stationList: [],
    provider: undefined,
    providerToken: undefined,
    authState: AuthStateEnum.AutoLogin,
  })),
)
