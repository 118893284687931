import {Component, Input, OnInit} from '@angular/core';
import {MatError, MatFormField} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {MatTooltip} from "@angular/material/tooltip";
import {FormControl, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms";
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {DmsMetaService} from "../../../../../services/api/dms-meta.service";
import {DmsDictItemsByType} from "../../../../../model/dmsDictItemsByType";
import {DmsDictItem} from "../../../../../model/dmsDictItem";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-meta-data-szotar',
  standalone: true,
    imports: [
        MatError,
        MatFormField,
        MatOption,
        MatSelect,
        NgForOf,
        NgIf,
        MatTooltip,
        ReactiveFormsModule,
        TranslateModule
    ],
  templateUrl: './meta-data-szotar.component.html',
  styleUrls: ['../metadata-style.scss']
})
export class MetaDataSzotarComponent extends BaseMetadataAbstract<number, string, number> implements OnInit{

  @Input() params: string = '';
  @Input() szotarOptionList: DmsDictItemsByType[] = [];

  optionList: DmsDictItem[] = [];

  selectedOption!: DmsDictItem;

  szotarFormControl!: FormControl;

  constructor(public szotarService: DmsMetaService) {
    super();
  }

  ngOnInit() {
    if(this.visibility!='MT_O') {
      if(this.szotarOptionList && this.szotarOptionList.length > 0) {
        this.fillWithInputList();
      } else {
        //this.fillWithRequestResp();
      }
    }
    this.initializeFormControl();
    this.szotarFormControl.markAllAsTouched();
  }

  private fillWithInputList(){
    let typeIdList = this.params.split(',').map(Number);
    typeIdList.forEach(param => {
      const foundItem = this.szotarOptionList.find(item => Number(item.dictTypeId) === param);
      if (foundItem) {
        foundItem.items?.forEach(data => {
          this.optionList.push(data);
        })
      }
    });
    this.setInitialSelectedOption();
  }

  // private fillWithRequestResp() {
  //   this.szotarService.readDmsSzotarListByTypeId$(this.params).subscribe(response => {
  //     this.optionList = response.result[0].items;
  //     this.setInitialSelectedOption();
  //   });
  // }

  private setInitialSelectedOption() {
    if(this.visibility != 'MT_K') {
      this.optionList.unshift({dictItemId: 0, dictItemName: '---'})
    }
    this.selectedOption = this.optionList.find((item) => (item.dictItemId == this.value))!;
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }

    this.szotarFormControl = new FormControl(this.value, validators);
  }

  setValue(selectedOption: DmsDictItem){
    this.onChangeValue(selectedOption.dictItemId);
    this.onChangeHumanValue(selectedOption.dictItemName);
  }

}
