import {CanActivateFn, Router} from '@angular/router';
import {AnyLoginResponse, LoginResponseType} from "../../mvp-auth";
import {inject} from "@angular/core";
import {catchError, map, of} from "rxjs";
import {MvpApiHttpClient} from "../../mvp-common";

export const adminGuard: CanActivateFn = (route, state) => {
  // const store = inject(LoginStore);
  // const router = inject(Router)
  //
  // return store.selectLoginState().pipe(
  //   first(),
  //   map(loginState => {
  //
  //     const roles = loginState.credentials?.roles || [];
  //
  //     console.log('available roles: ', roles);
  //
  //     if (!roles || roles.length===0) {
  //       router.navigate(['/404']);
  //       return false;
  //     }
  //
  //     const requiredRoles = route.data['requiredRole'] as string | string[];
  //     const hasRole = Array.isArray(requiredRoles)
  //       ? requiredRoles.every(role => roles.includes(role))
  //       : roles.includes(requiredRoles);
  //
  //     if (!hasRole) {
  //       router.navigate(['/404']);
  //       return false;
  //     }
  //
  //     return true;
  //   })
  // );

  const api = inject(MvpApiHttpClient);
  const router = inject(Router);

  return api.get<AnyLoginResponse>('/whoami', {}).pipe(
    map(response => {
      // Check if the response is valid

      const isAuthorized = response.type === LoginResponseType.Success
        && response.roles
        && response.roles.length > 0
        && (
          route.data['requiredRole'] ?
            ( Array.isArray(route.data['requiredRole'])
              ? (route.data['requiredRole'] as string[]).some(role => response.roles?.includes(role))
              : response.roles.includes(route.data['requiredRole'] as string)
            )
            : true
        );

      // const isAuthorized = response.type === LoginResponseType.Success
      //   && response.roles
      //   && response.roles.length > 0;
      //
      // if(isAuthorized) {
      //   const requiredRoles = route.data['requiredRole'] as string | string[];
      //   isAuthorized = Array.isArray(requiredRoles)
      //     ? requiredRoles.every(role => response.roles.includes(role))
      //     : response.roles.includes(requiredRoles);
      // }

      if (!isAuthorized) {
        router.navigate(['/404']);
        return false
      }
      return isAuthorized;
    }),
    catchError(error => {
      // Handle error, e.g., navigate to an error page
      router.navigate(['/404']);
      return of(false); // Return false in case of an error
    })
  );

};
