/**
 * User: harold
 * Date: 2023.08.09.
 */
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {LoginState} from "../types";

@Injectable({
  providedIn: 'root',
})
export class LoginStore extends Store<{ loginState: LoginState, credentials: LoginState }> {
  selectLoginState(): Observable<LoginState> {
    return super.select('loginState');
  }
}
