import {Component, Input, OnInit} from '@angular/core';
import {DmsMfTorzsItem} from "../../../../../model/dmsMfTorzsItem";
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {DmsMetaService} from "../../../../../services/api/dms-meta.service";
import {DmsMfTorzsItemsByLeiro} from "../../../../../model/dmsMfTorzsItemsByLeiro";
import {FormControl, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-meta-data-mftorzs',
  templateUrl: './meta-data-mftorzs.component.html',
  styleUrls: ['../metadata-style.scss']
})
export class MetaDataMftorzsComponent extends BaseMetadataAbstract<number, string, number> implements OnInit{

  @Input() params: string = '';
  @Input() mfTorzsOptionList: DmsMfTorzsItemsByLeiro[] = [];

  optionList: DmsMfTorzsItem[] = [];

  selectedOption!: DmsMfTorzsItem;

  mftorzsFormControl!: FormControl;

  constructor(public mfTorzsService: DmsMetaService) {
    super();
  }

  ngOnInit() {
    if(this.visibility!='MT_O') {
      if(this.mfTorzsOptionList && this.mfTorzsOptionList.length > 0) {
        this.fillWithInputList();
      } else {
        //this.fillWithRequestResp();
      }
    }
    this.initializeFormControl();
    this.mftorzsFormControl.markAllAsTouched();
  }

  private fillWithInputList(){
    let leiroIdList = this.params.split(',').map(Number);
    leiroIdList.forEach(param => {
      const foundItem = this.mfTorzsOptionList.find(item => Number(item.mfTorzsLeiroId) === param);
      if (foundItem) {
        foundItem.items?.forEach(data => {
          this.optionList.push(data);
        })
      }
    });
    this.setInitialSelectedOption();
  }

  // private fillWithRequestResp() {
  //   this.mfTorzsService.readDmsMfTorzsListByLeiroId$(this.params).subscribe(response => {
  //     this.optionList = response.result[0].items;
  //     this.setInitialSelectedOption();
  //   });
  // }

  private setInitialSelectedOption() {
    if(this.visibility != 'MT_K') {
      this.optionList.unshift({mfTorzsId: 0, mfTorzsName: '---'})
    }
    this.selectedOption = this.optionList.find((item) => (item.mfTorzsId == this.value))!;
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }

    this.mftorzsFormControl = new FormControl(this.value, validators);
  }

  setValue(selectedOption: DmsMfTorzsItem){
    this.onChangeValue(selectedOption.mfTorzsId);
    this.onChangeHumanValue(selectedOption.mfTorzsName);
  }

}
