import {Component, Inject} from '@angular/core';
import {NgIf} from "@angular/common";
import {SharedModule} from "../../../modules/shared/shared.module";
import {DmsAttachmentsService} from "../../../services/api/dms-attachments.service";
import {DocusignService} from "../../../services/api/docusign.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {IncomingDataAttachments} from "../../../shared/interfaces/shared/IncomingDataAttachments";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-dialog-attachment-box',
  standalone: true,
  imports: [
    NgIf,
    SharedModule,
    MatIconButton,
    MatMiniFabButton,
    TranslateModule
  ],
  templateUrl: './dialog-attachment-box.component.html',
  styleUrl: './dialog-attachment-box.component.scss'
})
export class DialogAttachmentBoxComponent{

  constructor(
    public dmsAttachmentsService: DmsAttachmentsService,
    private docusignService: DocusignService,
    public dialogRef: MatDialogRef<DialogAttachmentBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IncomingDataAttachments[],
  ) {
    dialogRef.disableClose = true;
  }

  download(attachmentGuid: string) {
    this.dmsAttachmentsService.readDmsAttachmentByGuid$(attachmentGuid);
  }

  signDoc(attachmentGuid: string) {
    this.docusignService.docusignGetUrlByAttachmentGuid$(attachmentGuid).subscribe( response => {
      window.open(response.result.url);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
