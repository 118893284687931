/**
 * User: harold
 * Date: 2023.08.16.
 */

export type AuthProvider = 'GOOGLE' | 'MICROSOFT';

/**
 * @see AuthErrorCode.php
 */
export enum AuthErrorCode {
  AuthenticationRequired = 1,
  UserAuthenticationRequired = 2,
  StationSelectionRequired = 3, // station adat is szukseges
  TokenVerificationFailed = 4, // pl. lejart
  TokenAudienceMismatch = 5, // a backend a token cimzettje!??
  StationPermissionDenied = 6, // nincs a userhez a megadott station => nem hasznalhatja
  InvalidStation = 7, // nem letezo station
  ProviderPermissionDenied = 8, // a station nem tamogatja az auth provider-t
  TokenError = 9, // token dekodolasi hiba
  TokenIncompletePayload = 10, // valami nem koser a payload-al
  RenewFailed = 11, // az access token nem hozhato letre, mert nincs vagy lejart vagy mar nincs joga a refresh token-nek

  // * user+station specific *
  StationAuthenticationRequired = 100, // ultimate oldali authentikacio szukseges

  // -----------

  // *** 403 ***
  RolePermissionDenied = 1000,
}

export enum AuthStateEnum {
  Unknown,
  LoginNeeded,
  LoginCompleted,
  LoginAborted,
  LoginMultiStation,
  AutoLogin,
}

export enum AuthTypeEnum {
  NotLoggedIn,
  UserOnly,
  UserAndStation
} // export interface LoginRequest {

export interface Credentials {
  email: string;
  provider: AuthProvider;
  station: string | null;
  roles: any|undefined;
}

export interface LoginState {
  authType: AuthTypeEnum;
  authState: AuthStateEnum;
  credentials?: Credentials;
  stationList: string[];
  provider?: AuthProvider;
  providerToken?: string;
}

// export const emptyLoginState = {
//   authType: AuthTypeEnum.NotLoggedIn,
//   authState: AuthStateEnum.Unknown,
//   stationList: [],
// }

export interface AuthInfo {
  state: AuthTypeEnum;
  stationId: string | null;
}
