<div style="width: 100%; display: flex; justify-content: space-between">
  <h2 mat-dialog-title style="margin-bottom: 0">{{'ATTACHMENTS' | translate}}</h2>
  <button mat-icon-button (click)="onNoClick()"><mat-icon>close</mat-icon></button>
</div>

<mat-dialog-content>

  <div *ngFor="let item of data">
      <div style="display: flex; flex-direction: column; padding-bottom: 12px">
        <div style="display: flex; flex-flow: row wrap; align-items: center; justify-content: space-between; margin-bottom: 6px; column-gap: 24px">
          <div style="flex: 1; min-width: 250px; word-break: break-word">{{item.name}}</div>
          <div style="display: flex; align-items: center; flex: 1; justify-content: space-between; column-gap: 24px">
            <div *ngIf="item.attachmentType" style="font-weight: 500">{{item.attachmentType}}</div>
            <span class="example-spacer"></span>
            <div *ngIf="item.attachmentGuid">
              <button mat-mini-fab style="margin: 6px" matTooltip="{{'SIGN' | translate}}" matTooltipPosition="below"
                      [disabled]="item.isTorolt"
                      (click)="signDoc(item.attachmentGuid)">
                <mat-icon>gesture</mat-icon>
              </button>
              <button mat-mini-fab style="margin: 6px" matTooltip="{{'DOWNLOAD' | translate}}" matTooltipPosition="below"
                      [disabled]="item.isTorolt"
                      (click)="download(item.attachmentGuid)">
                <mat-icon>save_alt</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-divider class="divider-position"></mat-divider>
      </div>
  </div>

</mat-dialog-content>
