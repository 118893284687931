import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorPageService {

  private _errorMessage: string = '';
  constructor() { }

  get errorMessage(): string {
    return this._errorMessage || 'PAGE_NOT_FOUND';
  }

  set errorMessage(value: string) {
    this._errorMessage = value;
  }

}
