<div class="metadata-div">

  <span class="input-field-label" [matTooltip]="label" matTooltipPosition="above">
    <b>{{label}}</b>
  </span>

  <div *ngIf="visibility === 'MT_O'" class="only-read-metadata-data-div">
    {{humanvalue}}
  </div>
  <mat-form-field *ngIf="visibility === 'MT_K' || visibility === 'MT_M'"
                  [color]="dateFormControl.invalid ? 'warn' : 'primary'">
    <input matInput [required]="visibility === 'MT_K'"
           [matDatepicker]="picker"
           [formControl]="dateFormControl"
           placeholder="YYYY. MM. DD."
           (dateChange)="formatNewValue($event.value)">
    <mat-datepicker-toggle matIconSuffix [for]="picker" class="calendar-button"></mat-datepicker-toggle>
    <mat-datepicker touchUi="true" #picker></mat-datepicker>
    <mat-error *ngIf="dateFormControl.hasError('required')">
      {{'MANDATORY_FIELD' | translate}}
    </mat-error>
  </mat-form-field>

</div>
